<template>
  <div
    class="container inside-wrapper"
    id="contact"
    data-aos="fade-up"
    data-aos-duration="1000"
  >
    <div class="row back-image back-blue" style="">
      <div class="col-md-5 col-heading">
        <h2 class="heading wow text-light">
          Contact <span class="lnr lnr-map"></span>
        </h2>
        <p class="text-dark wow text-light subheading text-justify">
          If you are looking for consulting and/or business enterprise solutions
          then please send us an email or give a call. We are always looking to
          assist new clients achieve their goals. Here are a list of things we
          can help with:
          <br /><br />
        </p>
        <ul type="none" class="contactPoints">
          <li>
            <i class="fa fa-minus text-white">&nbsp;&nbsp;</i> Website
            Design/Development
          </li>
          <li>&nbsp;</li>
          <li>
            <i class="fa fa-minus text-red">&nbsp;</i> Augmenting Developer
            Teams
          </li>
          <li>&nbsp;</li>
          <li>
            <i class="fa fa-minus text-white">&nbsp;</i> Technical Documentation
          </li>
          <li>&nbsp;</li>
          <li><i class="fa fa-minus text-red">&nbsp;</i> Mobile Development</li>
        </ul>
        <br />
        <a
          href="/projects"
          class="btn-red hvr-sweep-to-right hvr-sweep-to-right-red"
          >View Our Projects</a
        >
      </div>
    </div>

    <div class="row back-white contact-second"></div>

    <div class="row back-white mainmap contact-us-section">
      <div class="col-md-5 col-xs-12">
        <div class="contactcontent">
          <div class="row">
            <div class="col-sm-12">
              <h2 class="heading wow text-blue">Contact Us</h2>
            </div>
          </div>
          <div class="row border-bottom col-pad-bottom">
            <div class="col-md-2">
              <img src="../assets/img/icon-location.png" class="contact-icon" />
            </div>
            <div class="col-md-10">
              <h4 class="text-dark text-bold">Location:</h4>
              <ul type="none">
                <li class="text-dark">
                  701 S. 50th St. <br />Philadelphia, PA
                </li>
              </ul>
            </div>
            <br />
          </div>

          <div class="row border-bottom col-pad-bottom">
            <br />
            <div class="col-md-2">
              <img src="../assets/img/icon-mail.png" class="contact-icon" />
            </div>
            <div class="col-md-10">
              <h4 class="text-dark text-bold">Mail:</h4>
              <ul type="none">
                <li class="text-dark">
                  &#105;&#110;&#102;&#111;&#64;&#111;&#99;&#101;&#97;&#110;&#114;&#105;&#110;&#103;&#116;&#101;&#99;&#104;&#46;&#99;&#111;&#109;
                </li>
              </ul>
            </div>
          </div>

          <div class="row">
            <br />
            <div class="col-md-2">
              <img src="../assets/img/icon-phone.png" class="contact-icon" />
            </div>
            <div class="col-md-10">
              <h4 class="text-dark text-bold">Phone:</h4>
              <ul type="none">
                <li class="text-dark">
                  &#40;&#56;&#53;&#54;&#41;&#32;&#52;&#52;&#56;&#45;&#52;&#51;&#54;&#56;
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 col-xs-12 team-section">
        <div class="row" style="">
          <div class="col-md-12 col-heading">
            <h2 class="heading wow text-blue">
              Team <span class="lnr lnr-link"></span>
            </h2>
            <p class="text-dark wow text-dark subheading text-justify">
              When we started Ocean Ring Technologies, we wanted to work with
              like-minded technologists. People who shared a passion for their
              craft and also enjoyed learning new things not just in technology.
              Each of us has our different perspectives and have met many people
              that we have learned from and shared our skills with.
              <br /><br />
            </p>

            <p class="text-dark wow text-dark subheading text-justify">
              One of the core values of our company is to give back and show
              that ORT cares about our community. We have members from various
              parts of the world which adds to our diversity but also allows us
              to participate in events such as hackathons and code days to share
              knowledge to assist the next generation of technologists.<br /><br />
            </p>
            <h4 class="award-heading wow text-blue">
              Awards <span class="lnr lnr-diamond"></span>
            </h4>
            <a href="https://www.expertise.com/pa/philadelphia/mobile-app-development" style="display:inline-block; border:0;"  target="_blank">
                <img style="width:200px; display:block;" width="200" height="160" src="https://res.cloudinary.com/expertise-com/image/upload/f_auto,fl_lossy,q_auto/w_auto/remote_media/awards/pa_philadelphia_mobile-app-development_2021_transparent.svg" alt="Mobile App Developers" />
            </a>      
          </div>
        </div>
      </div>
      <p class="clearfix"></p>
    </div>

    <div class="row contact-fourth back-red">
      <div class="col-sm-5 bottom-links text-left">
        <router-link to="/news">
          <h2 class="heading text-light">
            <i class="fa fa-long-arrow-left"> </i> News
          </h2>
        </router-link>
      </div>
      <div class="col-sm-2">
        <p></p>
      </div>
      <div class="col-sm-5 bottom-links text-right">
        <router-link to="/contact">
          <h2 class="heading text-light">
            Contact <i class="fa fa-long-arrow-right"> </i>
          </h2>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  async mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.award-heading {
    font-size: 40px;
    color: #0e263f;
    font-weight: 900;
    margin-bottom: 30px;
}
</style>
